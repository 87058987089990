<template>
  <v-dialog max-width="400" v-model="dialog">
    <template #activator="{ on }">
      <v-btn icon color="primary" v-on="on">
        <v-icon>mdi-card-account-details</v-icon>
      </v-btn>
    </template>
    <template>
      <v-card class="mx-auto">
        <v-card-title>
          {{ member.fullName }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div>
            <a :href="`mailto:${member.email}`" class="decoration-none" target="_blank">
              <div class="">
                <v-btn icon color="primary">
                  <v-icon> mdi-email </v-icon>
                </v-btn>
                {{ member.email }}
              </div>
            </a>
          </div>
          <div>
            <div v-for="phone in phones" :key="phone.number">
              <CallMessage :number="phone.number" :type="phone.label" large v-if="phone.number">
              </CallMessage>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      default: null
    }
  },
  components: {
    CallMessage: () => import('../CallMessage.vue')
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    phones() {
      try {
        return this.member.phone || [];
      } catch {
        return [];
      }
    }
  }
};
</script>

<style>
</style>