import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","color":"primary"}},on),[_c(VIcon,[_vm._v("mdi-card-account-details")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.member.fullName)+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('div',[_c('a',{staticClass:"decoration-none",attrs:{"href":("mailto:" + (_vm.member.email)),"target":"_blank"}},[_c('div',{},[_c(VBtn,{attrs:{"icon":"","color":"primary"}},[_c(VIcon,[_vm._v(" mdi-email ")])],1),_vm._v(" "+_vm._s(_vm.member.email)+" ")],1)])]),_c('div',_vm._l((_vm.phones),function(phone){return _c('div',{key:phone.number},[(phone.number)?_c('CallMessage',{attrs:{"number":phone.number,"type":phone.label,"large":""}}):_vm._e()],1)}),0)])],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }